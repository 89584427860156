@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

#state_rows {
  @include media-breakpoint-up(lg) {
    height: 300px;
  }

  @include media-breakpoint-only(md) {
    height: 400px;
  }

  @include media-breakpoint-down(md) {
    height: auto;
  }
}