@media print {
  #hero, .hero {
    padding-top: 0.25;
    padding-bottom:0.25;
  }

  @page {
    margin-top: 0.45in 0;
    margin-bottom: 0.45in 0;
  }

  .pagebreak { page-break-before: always; }

  .base-print-footer {
    /*
      The base footer styling is different than the report styling. 
      Also, because it relies heavily on the body of the page and the report
      has many DOM manipulations, it's easiest to hide the base footer and roll
      a seperate report-specific footer.
    */
    display: none !important;
  }
  
  .no-left-padding {
    padding-left: unset;
  }

  .no-right-padding {
    padding-right: unset;
  }

  .kinsnp-report-text {
    font-size: 14px !important;
    color: black !important;

    &.contact {
      line-height: 20px;
      white-space: pre-line;
      margin-top: -5px;
    }

    &.case-id {
      margin-top: -10px;
      a {
        color: black;
        font-size:  14px;
        text-decoration: none;
      }
    }
  }

  .most-likely-banner {
    background-color: rgba(61, 46, 173, 0.689);
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    height: 40px;
    min-width: 20vw;
    width: fit-content;
    border: 4px solid rgb(61, 46, 173);
    display: flex;

    .probability {
      margin-left: -1px;
      border: 1px solid rgb(61, 46, 173);
      background-color: rgb(61, 46, 173);
    }

    .relationship {
      margin-top :4px;
      margin-left: 25px;
      margin-right: 25px;
      text-align: center;
      width: 100%;
    }

    h6 {
      color: white;
    }
  }
}