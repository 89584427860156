@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

/* TODO comeback to pull these variables from the custom scss files instead */

$primary: rgb(69, 166, 175);
$dark-blue: rgb(50, 49, 116);
$solved-blue: #004b88;
$body-bg: #fafafa;
$funding-yellow: #edc04a;
$main-header: #343a40;
$sub-header: #727272;

/* base styling of all text */
p,
body,
li,
div {
    font-weight: 400;
    font-size: 1.06rem;
    line-height: 1.7rem;
}

h5,
h6,
p,
li,
div {
    color: $sub-header;
    font-family: Roboto;
}

body {
    font-size: 0.9rem;
    font-weight: 300;
    min-height: 100vh;
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
}

body.for_iframe {
    background-color: unset;
}

h1,
h2,
h3,
h4 {
    font-family: Viga;
    color: $main-header;
}

h2 {
    font-size: 1.65rem
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.4rem;
}

h5 {
    font-weight: 400;
    line-height: 1.563rem;
}

h6 {
    font-weight: 500;
}

h5,
h6 {
    font-size: 1.25rem;
}

label.form-check-label {
    display: block;
    line-height: 1.7;
}

@media(max-width:992px) {
    h2 {
        font-size: 2.0rem;
    }

    h3 {
        font-size: 1.375rem;
    }

    h4 {
        font-size: 1.25rem;
    }

    h5 {
        font-size: 1.2rem;
    }

    h6 {
        font-size: 1.1rem;
    }

    p,
    label {
        line-height: 1.563rem;
    }
}

@media(max-width:767px) {
    h2 {
        font-size: 1.375rem;
    }
}

footer {
    background: none;
    background-color: $dark-blue;
}

footer a,
footer h6 {
    font-family: Viga;
}

footer a {
    font-size: 1.06rem;
    margin-bottom: 5px;
    color: #fff;
}

footer a:hover {
    color: $primary;
    text-decoration: none;
}

footer h6 {
    font-size: 1.56rem;
}

.footer-logo {
    font-size: 1.85rem
}

.footer-copyright {
    opacity: 0.5;
    font-size: 1.06rem;
    font-family: Roboto;
    color: #fff;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

nav.nav {
    background-color: #fff;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto 0;
    transition: all 0.3s ease-in-out;
}

.navbar-nav {
    align-items: center;
}

nav.nav .navbar-nav .nav-link {
    margin: 0 30px;
    opacity: .7;
    color: #2d2d2d;
    width: fit-content;
    padding-top: 15px;
    padding-bottom: 15px;
}

#mainNav .btn,
.nav-item {
    font-size: .875rem;
}

/* to overlap .sticky-top */
.nav-sticky {
    z-index: 1021;
}

#mainNav .navbar-nav .nav-item {
    flex: 1 1 auto;
    flex-direction: column;
    width: fit-content;
    margin: 0 .5rem;
}

#mainNav .nav-section {
    flex-direction: row;
}

#mainNav .navbar-nav .nav-link {
    margin-left: auto;
    margin-right: auto;
}

#subNav .nav-link {
    color: #000;
    margin: 0;
    border-left: 2px solid;
    opacity: 1;
    font-size: 1.25rem;
    font-weight: 500;
    font-family: 'Roboto';
    line-height: 1.125rem;
    letter-spacing: 0;
}

.alert {
    font-size: 1.25rem;
    text-align: center;
    color: #000;
    z-index: 1;
}

#subNav .nav-link:first-child {
    border-left: 0;
}

nav.nav .navbar-nav .nav-link.active-link {
    border-bottom: 5px solid $dark-blue;
    font-weight: 700;
    padding-bottom: 10px;
}

nav.nav .navbar-nav .nav-link.active-link,
nav.nav .navbar-nav .nav-link:hover {
    opacity: 1;
}

/* Some classes I wish bootstrap had but I'll add them here */
.min-w-33 {
    min-width: 33.3333%;
}

.h-fit {
    height: min-content;
}

.w-fit {
    width: min-content;
}

.min-w-25 {
    min-width: 25%;
}

.min-w-50 {
    min-width: 50%;
}

.border-r {
    border-radius: .25rem;
}

.extra-rounded {
    border-radius: 1rem;
}

.max-w-75 {
    max-width: 75%;
}

/* end custom generic classes */

.container {
    background: none;
    box-shadow: none;
}

.btn {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    font-weight: 800;
}

.btn-xl {
    padding: 20px;
}

nav .btn {
    padding: 10px 33px;
    font-weight: 800;
    font-size: .625rem;
    line-height: .75rem;
    height: fit-content;
}

#mainNav .nav-item {
    text-transform: uppercase;
}

.nav-item {
    font-family: Raleway;
    font-weight: 600;
    font-size: .875rem;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
}


section.page-section.hero2 {
    background: #eaeaea;
}

.panel-container {
    height: 100%;
    position: relative;
    z-index: 1;
}

.hero-content {
    width: 100%;
}

.hero .page-title {
    max-width: 980px;
    text-align: center;
    position: relative;
    z-index: 1;
}

.hero .page-title h1 {
    font-size: 2.81rem;
}

.hero .page-title h2 {
    font-size: 3.125rem;
    margin-bottom: 1rem;
}

.register-option {
    border-width: 3px;
    border-style: dashed;
    cursor: pointer;
    border-color: lighten($dark-blue, 25%);
}

.register-option.selected {
    border-style: solid;
    border-color: $dark-blue;
}

.register-option.selected * {
    color: #000;
}

.bg-hexagons-top {
    background: top left/calc(min(42%, 500px)) no-repeat url("/static/img/left_illustration.svg"),
        top right/calc(min(42%, 470px)) no-repeat url("/static/img/right_illustration.svg");
}

.bg-hexagons-empty {
    background: top left/calc(min(42%, 500px)) no-repeat url("/static/img/left_illustration_two.svg"),
        top right/calc(min(42%, 470px)) no-repeat url("/static/img/right_illustration_two.svg");
}

.bg-hexagons-bottom {
    background: top left/calc(min(42%, 500px)) no-repeat url("/static/img/shape_story_left.svg"),
        top right/calc(min(42%, 470px)) no-repeat url("/static/img/shape_story_right.svg");
}

@media print {

    .bg-hexagons-top,
    .bg-hexagons-empty,
    .bg-hexagons-bottom {
        background: none;
    }
}

@media(max-width: 992px) {

    .hero .page-title h1,
    .hero .page-title h2 {
        font-size: 1.75rem;
    }

    .hero h5 {
        font-size: 1.06rem;
        line-height: 1.563rem;
    }

    .hero-content {
        height: initial;
        overflow: hidden;
        position: relative;
    }

    /* prevent the dropdown menu from "floating" on mobile menu */
    nav .dropdown-menu.show {
        transform: none !important;
        position: static !important;
        text-align: center;
    }

    #mainNav .navbar-nav .nav-item,
    .dropdown-menu.show {
        width: 100%;
    }

    #mainNav .nav-section {
        flex-direction: column;
        margin-left: 0;
    }

    #subNav .nav-link,
    .alert {
        font-size: .813rem;
    }

    #mainNav .navbar-nav .nav-item {
        margin: 0 auto;
    }
}

@media(max-width: 768px) {
    .article {
        max-width: 400px;
    }
}

.dnasolves-logo {
    flex: 1 30%;
    transition: opacity 0.3s ease-in-out;
    margin-left: 10px;
}

.dnasolves-slider {
    height: 1.75em;
}

.dnasolves-slider-wrapper,
.dnasolves-logo {
    font-family: Viga;
    font-size: 1.563rem;
}

.dnasolves-slider div {
    font-size: 1.5rem;
}

@media(max-width:300px) {

    .dnasolves-slider-wrapper,
    .dnasolves-logo {
        font-family: Viga;
        font-size: 1.263rem;
    }

    .dnasolves-slider div {
        font-size: 1.2rem;
    }
}

.dnasolves-slider-wrapper,
.dnasolves-logo,
.dnasolves-slider div {
    font-weight: normal;
    color: #2d2d2d;
}

.navbar-toggler:focus {
    outline: 0;
}

.menu_burger {
    transition: opacity ease-in-out .3s;
    cursor: pointer;
    height: 20px;
    width: 28px;
    position: relative;
    display: block;
}

.menu_burger div {
    position: absolute;
    left: 0;
    width: 28px;
    height: 3px;
    background: #000000;
}

.menu_burger div:nth-child(1) {
    top: 0;
    transition: transform ease-in-out .3s;
}

.menu_burger div:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
    transition: all ease-in-out .3s;
}

.menu_burger div:nth-child(3) {
    bottom: 0;
    transition: transform ease-in-out .3s;
}

.menu_burger.isActive div:nth-child(1) {
    transform: rotate(45deg) translateY(12px);
}

.menu_burger.isActive div:nth-child(2) {
    transform: translateX(10px);
    opacity: 0;
}

.menu_burger.isActive div:nth-child(3) {
    transform: rotate(-45deg) translateY(-12px);
}

.article {
    height: initial;
}

.article .article-info .progress {
    height: 15px;
}

.article-img,
.article-container {
    position: relative;
    overflow: hidden;
}

.author-block img {
    height: 9.3em;
    width: 9.3em;
}

@media(max-width:400px) {
    .author-block img {
        height: 5em;
        width: 5em;
    }

}

.donor {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    width: 20em;
    height: 6em;
    margin: .5rem;
    border-radius: .25rem;
    background-color: white;
    padding: .25em;
}

.donor img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.badge {
    font-weight: 400;
}

.active .bs-stepper-circle {
    background-color: $primary;
}

@media print {
    a.navbar-logo {
        text-decoration: none;
    }

    body {
        background-color: white;
    }

    .bg-illustration,
    .navbar-toggler {
        display: none;
    }

    footer {
        background: inherit;
        color: inherit;
    }
}

@media(max-width:350px) {

    .dnasolves-slider-wrapper,
    .dnasolves-slider div,
    .dnasolves-logo {
        font-size: 1.3rem;
    }

    .dnasolves-slider-wrapper img {
        height: 27px;
    }

}

@media(max-width:300px) {

    .dnasolves-slider-wrapper,
    .dnasolves-slider div,
    .dnasolves-logo {
        font-size: 1.1rem;
    }

    .dnasolves-slider-wrapper img {
        height: 24px;
    }

}

.terms ol {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.terms ol li {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.terms .section-name {
    font-weight: bold;
    text-decoration: underline;
}

.terms .subsection-name {
    text-decoration: underline;
}

.terms-section {
    ol {
      list-style-type: none;
      counter-reset: item;
      
      li {
        counter-increment: item;
        margin-bottom: 10px;
        
        &:before {
          content: counter(item) ". ";
        }
        
        .terms-subsection {
          counter-reset: subitem;
          
          li {
            counter-increment: subitem;
            margin-bottom: 5px;
            
            &:before {
              content: counter(item) "." counter(subitem) " ";
            }
            
            .terms-subsection-l2 {
              counter-reset: subitem-l2;
              
              li {
                counter-increment: subitem-l2;
                margin-bottom: 5px;
                
                &:before {
                  content: "(" counter(subitem-l2, lower-alpha) ") ";
                }
                
                .terms-subsection-l3 {
                  counter-reset: subitem-l3;
                  
                  li {
                    counter-increment: subitem-l3;
                    margin-bottom: 5px;
                    
                    &:before {
                      content: "(" counter(subitem-l3, lower-roman) ") ";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }  

.terms .section-reference {
    text-decoration: underline;
}

.terms .question {
    text-decoration: underline;
}

.terms .definition {
    font-style: italic;
    font-weight: bold;
}


.corner-ribbon {
    width: 200px;
    background: #e43;
    position: absolute;
    top: 25px;
    left: -50px;
    font-size: 1.85rem;
    text-align: center;
    line-height: 2.5rem;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.corner-ribbon.sticky {
    position: absolute;
}

.corner-ribbon.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}

.corner-ribbon.top-right {
    top: 25px;
    right: -50px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    z-index: 3
}

summary.news-card {
    box-shadow: 2px 2px 5px #dee2e6;
    margin: .75rem 0;
    border-radius: 5px;
}

.read-overlay {
    position: absolute;
    color: #fff;
    text-align: center;
    display: none !important;
    z-index: 2
}

.read-overlay h6 {
    font-size: 1.5rem
}

.article:hover .read-overlay {
    display: flex !important;
    align-items: center;
}

.article-img,
.img-bg,
.read-overlay {
    height: 200px;
    background-size: cover;
    background-position: center;
}

div.feed-image {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    min-height: 12em;
    min-width: 12em;
}


div.topimg div.feed-image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.leftimg div.feed-image {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

a.anchor {
    padding-top: 65px;
    margin-top: -65px;
    display: inline-block;
    /* required for webkit browsers */
}

.bg-solved {
    background-color: $solved-blue;
}

.bg-update {
    background-color: $funding-yellow;
}

.btn-outline-primary-fill {
    @extend .btn-outline-primary;
    background-color: white;

    &:hover {
        @extend .btn-outline-primary;
    }
}

#newsletter-signup-modal {
    top: 15vh;
}

#newsletter-signup-modal form,
#newsletter-signup-modal button {
    z-index: 999;
    position: relative;
}

#newsletter-signup-modal .modal-content {
    background: $dark-blue;
    overflow: hidden;
}

#newsletter-signup-modal h5,
#newsletter-signup-modal h3,
#newsletter-signup-modal p,
#newsletter-signup-modal label,
#newsletter-signup-modal .text-muted {
    color: white !important;
}

#state_rows {
    @include media-breakpoint-up(lg) {
        height: 300px;
    }

    @include media-breakpoint-only(md) {
        height: 400px;
    }

    @include media-breakpoint-down(md) {
        height: auto;
    }
}

#web-submit {
    white-space: normal;
}

.InputElement {
    @include media-breakpoint-down(md) {
        font-size: 1rem;
    }
}

#preset_contribution_btns button {
    width: 6rem;
}