// As described in https://getbootstrap.com/docs/4.5/getting-started/theming/#importing

// Custom.scss
$primary: rgb(69, 166, 175);
$dark-blue: rgb(50, 49, 116);
$warning: rgb(237, 192, 74);
$theme-colors: (
  "dark-blue": $dark-blue,
  "yellow": $warning,
  "orange": rgb(242, 126, 10),
  "admin": rgb(140, 188, 183),
  "light-gray": rgb(249, 249, 249),
  "light-blue": rgb(122, 210, 218),
);

$font-family-base: Viga;
$container-max-widths: (
  sm: 1450px,
  md: 1450px,
  lg: 1450px,
  xl: 1450px,
);
$body-bg: #fafafa;
$input-btn-font-family: Raleway;

// make input fields appear as form-control-lg by default so that we can still use form-control-sm if needed
$input-btn-padding-x: 1rem;
$input-btn-padding-y: 0.5rem;
$input-btn-font-size: 1.25rem;

$link-color: rgba(45, 45, 45, 0.7);
$btn-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
$badge-font-weight: 400;
// Required
@import "bootstrap/functions";
@import "bootstrap/mixins";
@import "bootstrap/variables";

// Optional modules
// Commented out if they are unused.
@import "bootstrap/alert";
@import "bootstrap/badge";
@import "bootstrap/breadcrumb";
@import "bootstrap/button-group";
@import "bootstrap/buttons";
@import "bootstrap/card";
@import "bootstrap/carousel";
@import "bootstrap/close";
@import "bootstrap/code";
@import "bootstrap/custom-forms";
@import "bootstrap/dropdown";
@import "bootstrap/forms";
@import "bootstrap/grid";
@import "bootstrap/images";
@import "bootstrap/input-group";
@import "bootstrap/jumbotron";
@import "bootstrap/list-group";
@import "bootstrap/media";
@import "bootstrap/modal";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/pagination";
@import "bootstrap/popover";
@import "bootstrap/print";
@import "bootstrap/progress";
@import "bootstrap/reboot";
@import "bootstrap/root";
// @import "bootstrap/spinners";
@import "bootstrap/tables";
// @import "bootstrap/toasts";
@import "bootstrap/tooltip";
@import "bootstrap/transitions";
@import "bootstrap/type";
@import "bootstrap/utilities";

@import "./slide_out_animation";
@import "./dnasolves_2021";
@import "./state_pages";
@import "./shared_cm";
@import "./kinsnp";
