.relationship-table {
  max-width: none !important;
}

.cm-popover-body {
  padding: 0px;
  line-height: 1.2rem;
}

.cm-popover-header {
  font-size: 0.7rem;
  padding: 0.1rem 0.5rem;
}

.shared-cm-table {
  width: 100%;
  max-width: 250px;
  margin-bottom: 0px;

  td {
    padding: 0rem 0.25rem;
  }

  .probability {
    font-size: 12px;
    font-weight: 'bold';
  }

  .relationship-name {
    font-size: 10px;
  }

  .data-source {
    font-size: 8px;
  }
}